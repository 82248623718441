<template>
  <v-app>
    <div v-if="isPortrait" class="orientation-message">
      Por favor, gira tu dispositivo a modo horizontal para una mejor experiencia.
    </div>
    <div v-else>
      <!-- El contenido de tu aplicación -->
    
    <div style="background: white;">
      <div style="font-weight: 700;color:#000;text-align: center;padding: 5px 0">
        ELECCIÓN PRESIDENCIAL 2024 | Emulador
      </div>
    </div>
    <v-main style="background: #E3E3E3;">
      <router-view/>
    </v-main></div>
  </v-app>
</template>
<style>
.orientation-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
  text-align: center;
  font-size: 24px;
  padding: 20px;
}
</style>
<script>

export default {
  name: 'App',

  data: () => ({
    isPortrait: false
  }),
  mounted() {
    this.checkOrientation();
    window.addEventListener('resize', this.checkOrientation);
    window.addEventListener('orientationchange', this.checkOrientation);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkOrientation);
    window.removeEventListener('orientationchange', this.checkOrientation);
  },
  methods: {
    checkOrientation() {
      this.isPortrait = window.innerHeight > window.innerWidth;
    }
  }
};
</script>
