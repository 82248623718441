<template>
    <div style="margin: 100px auto;display: block;width: 80%;">

        <v-layout row justify-center>
            <v-flex xs12 sm4></v-flex>
            <v-flex xs12 sm4 class="card-style">
                <v-card flat>
            <v-img :src="candidato.foto"></v-img>
            <div class="candidato-text">
                {{ candidato.candidato }}
              </div>
              <v-img :src="candidato.tarjeta"></v-img>
        </v-card>

            <div style="background: grey;padding: 10px;width: 100%;">
                <v-btn style="
                background: #D4D4D4;color: #000;padding: 10px 60px;
                border-radius: 5px;margin: 0 auto;display: block;
            " @click="votar">
            VOTAR
        </v-btn>
            </div>

            </v-flex>
            <v-flex xs12 sm4></v-flex>
        </v-layout>
    </div>
  </template>
  
  <style>
  .card-style {
    border: solid 1px #000;
    border-radius: 0;
  }
  .detalle-candidato-container {
    height: 100vh;
    display: block;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .candidato-text {
    padding: 2px 0;
    font-size: 16px;
    color: black;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  </style>
  
  <script>
  export default {
    name: 'DetalleCandidato',
    data() {
      return {
        candidato: null
      }
    },
    created() {
      // Obtener los datos del candidato desde sessionStorage
      const candidato = sessionStorage.getItem('selectedCandidato');
      if (candidato) {
        this.candidato = JSON.parse(candidato);
      } else {
        // Redirigir a la página principal si no hay datos del candidato
        this.$router.push('/');
      }
    },
    methods: {
      votar() {
        alert(`Has votado por ${this.candidato.candidato}`);
        this.$router.push('/');
        // Aquí puedes añadir lógica para manejar el voto
      }
    }
  }
  </script>
  