import { render, staticRenderFns } from "./DetalleCandidato.vue?vue&type=template&id=4e01fffc"
import script from "./DetalleCandidato.vue?vue&type=script&lang=js"
export * from "./DetalleCandidato.vue?vue&type=script&lang=js"
import style0 from "./DetalleCandidato.vue?vue&type=style&index=0&id=4e01fffc&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports